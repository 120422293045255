<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('businessList_shxq', { id: $route.query.id })">基本信息</span>
      <span @click="toUrl('addcommercial_jsxx_edit', { id: $route.query.id })">结算信息</span>
      <span @click="toUrl('addcommercial_qdxx_edit', { id: $route.query.id })">渠道信息</span>
      <span @click="toUrl('addcommercial_pzxx_edit', { id: $route.query.id })">产品信息</span>
      <span id="fzcolor">配置信息</span>
      <span
        v-if="detail.checkStatus == 'PASS'"
        @click="toUrl('addcommercial_dkxq_edit', { id: $route.query.id })">终端信息</span>
    </div>
    <div v-auth="'ACCOUNT:MCH:MCHS:CONFIG'">
      <!-- 通用配置 -->
      <div class="desc">
        <h5 class="jsxx">通用配置</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 200px">
              <p>商品名称配置</p>
            </td>
            <td style="width: 150px">
              <p>收款IP白名单</p>
            </td>
            <td style="width: 150px">
              <p>付款IP白名单</p>
            </td>
            <td>
              <p>限制信用卡</p>
            </td>
            <td>
              <p>商户渠道配置</p>
            </td>
            <!-- <td v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
							<p>商户平台代付</p>
            </td>-->
            <td>
              <p>终端配置</p>
            </td>
            <td>
              <p>电子账簿</p>
            </td>
            <td style="width: 60px">
              <p>操作</p>
            </td>
          </tr>
          <tr>
            <!-- 商品名称配置 -->
            <td style="width: 200px">
              <p>{{ commonConfig.body }}</p>
            </td>
            <!-- 收款IP白名单 -->
            <td style="width: 250px">
              <p class="hidden1" style="width: 250px">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="commonConfig.receiptIpWhiteList"
                  placement="top">
                  <span>{{ commonConfig.receiptIpWhiteList }}</span>
                </el-tooltip>
              </p>
            </td>
            <!-- 付款IP白名单 -->
            <td style="width: 250px">
              <p class="hidden1" style="width: 250px">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="commonConfig.payIpWhiteList"
                  placement="top">
                  <span>{{ commonConfig.payIpWhiteList }}</span>
                </el-tooltip>
              </p>
            </td>
            <!-- 限制信用卡 -->
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ commonConfig.limitPayConfigStatus==true ?
                "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="commonConfig.limitPayConfigStatus"
                  @change="
                    setCommonconfigStatus({
                      limitPayConfigStatus: commonConfig.limitPayConfigStatus,
                    })
                  "
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <!-- 商户渠道配置 -->
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ commonConfig.mchChannelConfigStatus==true ?
                "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="commonConfig.mchChannelConfigStatus"
                  @change="
                    setCommonconfigStatus({
                      mchChannelConfigStatus:
                        commonConfig.mchChannelConfigStatus,
                    })
                  "
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <!-- <td v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
							<p>
								<el-switch v-model="commonConfig.mchAgnpayConfigStatus" @change="setCommonconfigStatus({mchAgnpayConfigStatus:commonConfig.mchAgnpayConfigStatus})"
								 active-color="#13ce66"></el-switch>
							</p>
            </td>-->
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ commonConfig.mchTreminalStatus==true ? "已开启"
                : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch v-model="commonConfig.mchTreminalStatus"
                  @change="setCommonconfigStatus({mchTreminalStatus:commonConfig.mchTreminalStatus})"
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ commonConfig.tradeAccountStatus == true ?
                "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="commonConfig.tradeAccountStatus"
                  @change="setCommonconfigStatus({tradeAccountStatus:commonConfig.tradeAccountStatus})"
                  active-color="#13ce66">
                </el-switch>
              </p>
            </td>
            <td style="width: 60px">
              <p>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"
                  class="lianjie"
                  @click="setOtherShow">编辑</i>
              </p>
            </td>
          </tr>
        </table>
      </div>

      <!-- 权限配置 -->
      <div class="desc">
        <h5 class="jsxx">权限配置</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 200px">
              <p>店铺关联渠道</p>
            </td>
            <td>
              <p>店铺报备权限</p>
            </td>
            <td>
              <p>店铺免审模式</p>
            </td>
            <td>
              <p>贸易模式开关</p>
            </td>
            <td v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
              <p>贸易模式产品类型</p>
            </td>
            <td>
              <p>重复进件开关</p>
            </td>
            <td>
              <p>分账关联渠道</p>
            </td>
            <td>
              <p>分账入网权限</p>
            </td>
            <td style="width: 60px">
              <p>操作</p>
            </td>
          </tr>
          <tr>
            <td style="width: 200px">
              <p>{{ authConfig.storeMchChannelNo }}</p>
            </td>

            <!-- 报备权限开关 -->
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ authConfig.applyStoreStatus==true ? "已开启" :
                "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="authConfig.applyStoreStatus"
                  @change="
                    setAuthconfigStatus(1, {
                      applyStoreStatus: authConfig.applyStoreStatus,
                    })
                  "
                  active-color="#13ce66"></el-switch>
              </p>
            </td>

            <!-- 免审模式开关 -->
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ authConfig.freeTrialStatus==true ? "已开启" :
                "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="authConfig.freeTrialStatus"
                  @change="
                    setAuthconfigStatus(2, {
                      freeTrialStatus: authConfig.freeTrialStatus,
                    })
                  "
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <!-- 贸易模式开关 -->
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ authConfig.tradeType==true ? "已开启" : "已关闭" }}
              </p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="authConfig.tradeType"
                  @change="changeTradeType"
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <!-- 贸易模式产品类型 -->
            <td v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
              <p v-if="authConfig.productType == 'wxpay'">微信支付</p>
              <p v-if="authConfig.productType == 'alipay'">支付宝支付</p>
              <!-- <p v-if="authConfig.productType == 'unionpay'">银联支付</p> -->
            </td>
            <!-- 重复进件开关 -->
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ authConfig.filingStatus==true ? "已开启" : "已关闭"
                }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="authConfig.filingStatus"
                  @change="
                    setAuthconfigStatus(4, {
                      filingStatus: authConfig.filingStatus,
                    })
                  "
                  active-color="#13ce66"></el-switch>
              </p>
            </td>

            <!-- 分账入网权限开关 -->
            <td style="padding-left: 10px">
              <p>{{ authConfig.allocFundMchChannelNo }}</p>
            </td>
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ authConfig.allocFundStatus==true ? "已开启" :
                "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="authConfig.allocFundStatus"
                  @change="
                    setAuthconfigStatus(3, {
                      allocFundStatus: authConfig.allocFundStatus,
                    })
                  "
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <td style="width: 60px">
              <p>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"
                  class="lianjie"
                  @click="setAuthconfigShow">编辑</i>
              </p>
            </td>
          </tr>
        </table>
      </div>

      <!-- 智能路由配置 -->
      <div class="desc">
        <h5 class="jsxx">智能路由配置</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>路由阈值(%)</p>
            </td>
            <td>
              <p>路由粒度</p>
            </td>
            <td>
              <p>最小风控订单数</p>
            </td>
            <td>
              <p>交易商户数量</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v,i) in payProducts" :key="i">
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ v.routeThreshold }}</p>
            </td>
            <td>
              <p>{{ finenessShow[v.riskGranularity] }}</p>
            </td>
            <td>
              <p>{{ v.routeNum }}</p>
            </td>
            <td>
              <p>{{ v.tradeNum }}</p>
            </td>
            <td>
              <p>
                <i class="lianjie" @click="editPayIntell(v)" v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">编辑</i>
                <i class="lianjie" @click="delPayIntell(v,i)" style="padding-left:7px"
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/DELETE'">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6 class="xzjsxx" @click="intellRouteAdd" v-auth="'ACCOUNT:MCH:MCHS:CONFIG/ADD'"><span>+</span> 新增智能路由配置</h6>

      <!-- 限流配置 -->
      <div class="desc">
        <h5 class="jsxx">限流配置</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>接口名称</p>
            </td>
            <td>
              <p>限流规则</p>
            </td>
            <td>
              <p>限流阀值</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in limitConfigs" :key="i">
            <td style="padding-left: 10px">
              <p>{{ limitOption[v.interfaceName] }}</p>
            </td>
            <td>
              <p>{{ v.rule == 1 ? "次/秒" : "" }}</p>
            </td>
            <td>
              <p>{{ v.frequency }}</p>
            </td>
            <td style="width: 80px">
              <p>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"
                  class="lianjie"
                  @click="editLimit(v)">编辑</i>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/DELETE'"
                  class="lianjie"
                  @click="delLimit(v.id)">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-auth="'ACCOUNT:MCH:MCHS:CONFIG/ADD'"
        class="xzjsxx"
        @click="addLimitShow">
        <span>+</span> 新增限流配置
      </h6>

      <!-- 微信APP配置 -->
      <div class="desc">
        <h5 class="jsxx">微信APP配置</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>小程序主体</p>
            </td>
            <td>
              <p>小程序名称</p>
            </td>
            <td>
              <p>小程序APPID</p>
            </td>
            <td>
              <p>原始ID</p>
            </td>
            <td>
              <p>认证状态</p>
            </td>
            <td>
              <p>部署状态</p>
            </td>
            <td>
              <p>启用状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in appConfigs" :key="i">
            <td style="padding-left: 10px">
              <p>{{ v.principalName }}</p>
            </td>
            <td>
              <p>{{ v.appName }}</p>
            </td>
            <td>
              <p>{{ v.appId }}</p>
            </td>
            <td>
              <p>{{ v.originalId }}</p>
            </td>
            <td>
              <p>{{ v.verifyStatus ? "是" : "否" }}</p>
            </td>
            <td>
              <p>{{ v.deployStatus ? "是" : "否" }}</p>
            </td>
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="v.enabledStatus"
                  @change="setWechartApp(v)"
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <td>
              <p>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/DELETE'"
                  class="lianjie"
                  @click="delWechartApp(v.smallId)">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-auth="'ACCOUNT:MCH:MCHS:CONFIG/ADD'"
        class="xzjsxx"
        @click="addWechartAppShow">
        <span>+</span> 新增微信APP配置
      </h6>

      <!-- 小程序收银台模式配置 -->
      <div class="desc">
        <h5 class="jsxx">小程序收银台模式配置</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>小程序主体</p>
            </td>
            <td>
              <p>小程序名称</p>
            </td>
            <td>
              <p>小程序APPID</p>
            </td>
            <td>
              <p>原始ID</p>
            </td>
            <td>
              <p>认证状态</p>
            </td>
            <td>
              <p>部署状态</p>
            </td>
            <td>
              <p>启用状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in cashierConfigs" :key="i">
            <td style="padding-left: 10px">
              <p>{{ v.principalName }}</p>
            </td>
            <td>
              <p>{{ v.appName }}</p>
            </td>
            <td>
              <p>{{ v.appId }}</p>
            </td>
            <td>
              <p>{{ v.originalId }}</p>
            </td>
            <td>
              <p>{{ v.verifyStatus ? "是" : "否" }}</p>
            </td>
            <td>
              <p>{{ v.deployStatus ? "是" : "否" }}</p>
            </td>
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="v.enabledStatus"
                  @change="setSaleApp(v)"
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <td>
              <p>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/DELETE'"
                  class="lianjie"
                  @click="delSaleApp(v.smallId)">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-auth="'ACCOUNT:MCH:MCHS:CONFIG/ADD'"
        class="xzjsxx"
        @click="addSaleAppShow">
        <span>+</span> 新增小程序收银台模式配置
      </h6>

      <!-- 微信H5配置 -->
      <div class="desc">
        <h5 class="jsxx">微信H5配置</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>小程序主体</p>
            </td>
            <td>
              <p>小程序名称</p>
            </td>
            <td>
              <p>小程序APPID</p>
            </td>
            <td>
              <p>原始ID</p>
            </td>
            <td>
              <p>认证状态</p>
            </td>
            <td>
              <p>部署状态</p>
            </td>
            <td>
              <p>启用状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in h5Configs" :key="i">
            <td style="padding-left: 10px">
              <p>{{ v.principalName }}</p>
            </td>
            <td>
              <p>{{ v.appName }}</p>
            </td>
            <td>
              <p>{{ v.appId }}</p>
            </td>
            <td>
              <p>{{ v.originalId }}</p>
            </td>
            <td>
              <p>{{ v.verifyStatus ? "是" : "否" }}</p>
            </td>
            <td>
              <p>{{ v.deployStatus ? "是" : "否" }}</p>
            </td>
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="v.enabledStatus"
                  @change="setSaleH5(v)"
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <td>
              <p>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/DELETE'"
                  class="lianjie"
                  @click="delSaleH5(v.smallId)">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-auth="'ACCOUNT:MCH:MCHS:CONFIG/ADD'"
        class="xzjsxx"
        @click="addSaleH5Show">
        <span>+</span> 新增微信H5配置
      </h6>

      <!-- 微信扫码+配置 -->
      <div class="desc">
        <h5 class="jsxx">微信扫码+配置</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>小程序主体</p>
            </td>
            <td>
              <p>小程序名称</p>
            </td>
            <td>
              <p>小程序APPID</p>
            </td>
            <td>
              <p>原始ID</p>
            </td>
            <td>
              <p>认证状态</p>
            </td>
            <td>
              <p>部署状态</p>
            </td>
            <td>
              <p>启用状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in nativeConfigs" :key="i">
            <td style="padding-left: 10px">
              <p>{{ v.principalName }}</p>
            </td>
            <td>
              <p>{{ v.appName }}</p>
            </td>
            <td>
              <p>{{ v.appId }}</p>
            </td>
            <td>
              <p>{{ v.originalId }}</p>
            </td>
            <td>
              <p>{{ v.verifyStatus ? "是" : "否" }}</p>
            </td>
            <td>
              <p>{{ v.deployStatus ? "是" : "否" }}</p>
            </td>
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch
                  v-model="v.enabledStatus"
                  @change="setScanCode(v)"
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <td>
              <p>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/DELETE'"
                  class="lianjie"
                  @click="delScanCode(v.smallId)">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-auth="'ACCOUNT:MCH:MCHS:CONFIG/ADD'"
        class="xzjsxx"
        @click="addWechartScanShow">
        <span>+</span> 新增微信扫码+配置
      </h6>

      <!-- 公众号配置 -->
      <div class="desc">
        <h5 class="jsxx">聚合码配置</h5>
      </div>
      <div class="tab1" v-if="mpConfigs">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>公众号APPID</p>
            </td>

            <td>
              <p>公众号密钥</p>
            </td>
            <td style="padding-left: 10px">
              <p>小程序APPID</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ mpConfigs ? mpConfigs.appid : "" }}</p>
            </td>

            <td>
              <p>{{ mpConfigs ? mpConfigs.appsecret : "" }}</p>
            </td>

            <td>
              <p>{{ mpConfigs ? mpConfigs.mpappid : "" }}</p>
            </td>
            <td style="width: 80px">
              <p>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'"
                  class="lianjie"
                  @click="openPublic(2, mpConfigs)">编辑</i>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CONFIG/DELETE'"
                  class="lianjie"
                  @click="delPublic(mpConfigs)">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-if="hasData"
        v-auth="'ACCOUNT:MCH:MCHS:CONFIG/ADD'"
        @click="openPublic(1, {})"
        class="xzjsxx"
        :style="hasData ? 'margin-top:20px;' : ''">
        <span>+</span> 新增聚合码配置
      </h6>

      <!-- 退汇通知配置 -->
      <div class="desc">
        <h5 class="jsxx">退汇通知配置</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>通知地址</p>
            </td>
            <td>
              <p>启用状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ commonConfig.refundRemittanceUrl }}</p>
            </td>
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">{{ commonConfig.refundRemittanceStatus==true ?
                "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch v-model="commonConfig.refundRemittanceStatus"
                  :disabled="commonConfig.refundRemittanceUrl ? false : true" @change="refundConfigStatus(commonConfig)"
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <td>
              <p>
                <i v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'" class="lianjie"
                  @click="refundConfigEdit(commonConfig)">编辑</i>
              </p>
            </td>
          </tr>
        </table>
      </div>

      <!-- 资金解冻通知配置 -->
      <div class="desc">
        <h5 class="jsxx">资金解冻通知配置</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>通知地址</p>
            </td>
            <td>
              <p>启用状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>{{ commonConfig.thawNotifyUrl }}</p>
            </td>
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">{{ commonConfig.thawNotifyStatus==true ?
                "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'">
                <el-switch v-model="commonConfig.thawNotifyStatus"
                  :disabled="commonConfig.thawNotifyUrl ? false : true" @change="unfreezeConfigStatus(commonConfig)"
                  active-color="#13ce66"></el-switch>
              </p>
            </td>
            <td>
              <p>
                <i v-auth="'ACCOUNT:MCH:MCHS:CONFIG/EDIT'" class="lianjie"
                  @click="unfreezeConfigEdit(commonConfig)">编辑</i>
              </p>
            </td>
          </tr>
        </table>
      </div>

      <!-- <div class="btn">
				<el-button @click="toUrl('addcommercial_pzxx_edit',{id:$route.query.id})" class="back">上一步</el-button>
				<el-button @click="toUrl('businessList_shlb')" class="next" type="primary">保存</el-button>
      </div>-->
      <el-dialog title="修改通用配置" :visible.sync="userPayShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title">商品名称配置</div>
            <el-input
              v-model="fromData.body"
              maxlength="128"
              placeholder="输入商品名称配置"></el-input>
            <div class="overAccount_ul_title">收款IP白名单</div>
            <template v-for="(v, i) in fromData.receiptIpWhiteList">
              <el-input
                v-model="fromData.receiptIpWhiteList[i]"
                placeholder="输入IP">
                <template slot="append">
                  <i
                    class="el-icon-delete"
                    v-show="i > 0"
                    @click="fromData.receiptIpWhiteList.splice(i, 1)"></i>
                  <i
                    class="el-icon-plus"
                    v-show="i <= 0"
                    @click="fromData.receiptIpWhiteList.push('')"></i>
                </template>
              </el-input>
            </template>
          </li>
          <li class="ul-right">
            <div style="height: 83px"></div>
            <div class="overAccount_ul_title">付款IP白名单</div>
            <template v-for="(v, i) in fromData.payIpWhiteList">
              <el-input
                v-model="fromData.payIpWhiteList[i]"
                placeholder="输入IP">
                <template slot="append">
                  <i
                    class="el-icon-delete"
                    v-show="i > 0"
                    @click="fromData.payIpWhiteList.splice(i, 1)"></i>
                  <i
                    class="el-icon-plus"
                    v-show="i <= 0"
                    @click="fromData.payIpWhiteList.push('')"></i>
                </template>
              </el-input>
            </template>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="userPayShow = false">取 消</el-button>
          <el-button class="addBt" @click="setCommonconfig">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 微信APP配置 -->
      <el-dialog title="新增微信APP配置" :visible.sync="hasPayShow" width="490px">
        <ul class="overAccount_ul">
          <li style="width: 400px; margin: 0 auto">
            <div class="overAccount_ul_title"><i>*</i>新增小程序</div>
            <el-select v-model="appFrom" placeholder="选择小程序">
              <el-option v-for="item in applets" :key="item.smallId" :label="item.appName + '|' + item.principalName"
                :value="item.smallId"></el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="hasPayShow = false">取 消</el-button>
          <el-button class="addBt" @click="addWechartApp">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 限流配置 -->
      <el-dialog title="限流配置" :visible.sync="limitShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>接口名称</div>
            <el-select v-model="limitFrom.interfaceName" placeholder="选择接口">
              <el-option v-for="(v, i) in limitOption" :key="i" :label="v" :value="i"></el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>限流阀值</div>
            <el-input v-model="limitFrom.frequency" type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))" placeholder="输入限流阀值" min="0" />
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>限流规则</div>
            <el-select v-model="limitFrom.rule" placeholder="选择限流规则">
              <el-option label="次/秒" value="1"></el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="limitShow = false">取 消</el-button>
          <el-button class="addBt" @click="addLimit">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 新增小程序收银台模式配置 -->
      <el-dialog title="新增小程序收银台模式配置" :visible.sync="accountShow" width="490px">
        <ul>
          <li style="width: 400px; margin: 0 auto">
            <div class="overAccount_ul_title"><i>*</i>新增小程序</div>
            <!--  @change="saleChange" -->
            <el-select v-model="saleFrom" placeholder="选择小程序">
              <el-option v-for="item in applets" :key="item.smallId" :label="item.appName + '|' + item.principalName"
                :value="item.smallId"></el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="accountShow = false">取 消</el-button>
          <el-button class="addBt" @click="addSaleApp">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改权限配置 -->
      <el-dialog title="修改权限配置" :visible.sync="permissionShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title">
              <i v-if="permissionFrom.applyStoreStatus">*</i>店铺关联渠道
            </div>
            <el-select v-model="permissionFrom.storeMchChannelId" placeholder="选择渠道">
              <el-option v-for="(v, i) in channelDrop" :key="i" :label="v.mchNo" :value="v.mchChannelId"></el-option>
            </el-select>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title">
              <i v-if="permissionFrom.allocFundStatus">*</i>分账关联渠道
            </div>
            <el-select v-model="permissionFrom.allocFundMchChannelId" placeholder="选择渠道">
              <el-option v-for="(v, i) in channelDrop" :key="i" :label="v.mchNo" :value="v.mchChannelId"></el-option>
            </el-select>
          </li>
          <li class="ul_left">
            <div class="overAccount_ul_title">
              <i v-if="permissionFrom.allocFundStatus">*</i>贸易模式产品类型
            </div>
            <el-select v-model="permissionFrom.productType" placeholder="选择类型">
              <el-option v-for="(v, i) in productTypeData" :key="i" :label="v.label" :value="v.value"></el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="permissionShow = false">取 消</el-button>
          <el-button class="addBt" @click="setAuthconfig">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 微信H5配置 -->
      <el-dialog title="新增微信H5配置" :visible.sync="hasPayH5Show" width="490px">
        <ul class="overAccount_ul">
          <li style="width: 400px; margin: 0 auto">
            <div class="overAccount_ul_title"><i>*</i>新增小程序</div>
            <el-select v-model="appFrom" placeholder="选择小程序">
              <el-option v-for="item in applets" :key="item.smallId" :label="item.appName + '|' + item.principalName"
                :value="item.smallId"></el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="hasPayH5Show = false">取 消</el-button>
          <el-button class="addBt" @click="addSaleH5">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 微信扫码+配置 -->
      <el-dialog title="新增微信扫码+配置" :visible.sync="scanCodeShow" width="490px">
        <ul class="overAccount_ul">
          <li style="width: 400px; margin: 0 auto">
            <div class="overAccount_ul_title"><i>*</i>新增小程序</div>
            <el-select v-model="appFrom" placeholder="选择小程序">
              <el-option v-for="item in applets" :key="item.smallId" :label="item.appName + '|' + item.principalName"
                :value="item.smallId"></el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="scanCodeShow = false">取 消</el-button>
          <el-button class="addBt" @click="confirmScanCode">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 聚合码配置 -->
      <el-dialog :title="publicTitle + '聚合码配置'" :visible.sync="publicFlag" width="490px">
        <ul class="overAccount_ul">
          <li style="width: 400px; margin: 0 auto">
            <div class="overAccount_ul_title"><i></i>公众号APPID</div>
            <el-input v-model="publicForm.appid" placeholder="请输入公众号APPID">
            </el-input>
          </li>
          <li style="width: 400px; margin: 0 auto">
            <div class="overAccount_ul_title"><i></i>公众号密钥</div>
            <el-input v-model="publicForm.appsecret" placeholder="请输入公众号密钥">
            </el-input>
          </li>

          <li style="width: 400px; margin: 0 auto">
            <div class="overAccount_ul_title">
              <i></i>通过第三方创建的小程序
            </div>
            <!--  @change="saleChange" -->
            <el-select @clear="clearVerified" clearable v-model="publicForm.mpappid" placeholder="选择第三方小程序">
              <el-option v-for="item in verifiedList" :key="item.smallId"
                :label="item.appName + '|' + item.principalName"
                :value="item.appid"></el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="publicFlag = false">取 消</el-button>
          <el-button class="addBt" @click="confirmPublic">确 定</el-button>
        </span>
      </el-dialog>


      <!-- 智能路由配置 -->
      <el-dialog title="智能路由配置" :visible.sync="intellShow" width="490px"
        :title="editPayIndex !== null ? '修改智能路由配置' : '新增智能路由配置'">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>产品类型</div>
            <el-select v-model="intellFrom.productId" placeholder="选择产品类型" @change="productIdChange()"
              :disabled="editPayIndex !== null ? true : false ">
              <el-option v-for="(v, i) in payProductsChage" :key="i" :label="v.productName" :value="v.productId"
                :disabled="v.disabled">
              </el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>路由粒度</div>
            <el-select v-model="intellFrom.riskGranularity" placeholder="选择路由粒度" disabled>
              <el-option v-for="(v, i) in fineness" :key="i" :label="v.title" :value="v.value" :disabled="v.disabled">
              </el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>交易商户数量</div>
            <el-input v-model="intellFrom.tradeNum" type="number" placeholder="输入交易商户数量" min="1" />
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>路由阈值(0-100%)</div>
            <el-input v-model="intellFrom.routeThreshold" type="number" placeholder="输入路由阈值" min="0" max="100" />
            <div class="overAccount_ul_title"><i>*</i>最小风控订单数</div>
            <el-input v-model="intellFrom.routeNum" type="number" placeholder="输入最小风控订单数" min="0" />
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="intellShow = false">取 消</el-button>
          <el-button class="addBt" @click="addintellFrom" :disabled="buttonFlag">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 退汇通知配置 -->
      <el-dialog title="退汇通知配置" :visible.sync="refundConfigShow" width="490px" @close="refundConfigData = {}">
        <ul class="overAccount_ul">
          <li style="width: 400px; margin: 0 auto">
            <div class="overAccount_ul_title"><i></i>通知地址</div>
            <el-input v-model="refundConfigData.refundRemittanceUrl" placeholder="请输入通知地址"></el-input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="refundConfigShow = false,refundConfigData = {}">取 消</el-button>
          <el-button class="addBt" @click="refundConfigBtn">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 资金解冻通知配置 -->
      <el-dialog title="资金解冻通知配置" :visible.sync="unfreezeConfigShow" width="490px" @close="unfreezeConfigData = {}">
        <ul class="overAccount_ul">
          <li style="width: 400px; margin: 0 auto">
            <div class="overAccount_ul_title"><i></i>通知地址</div>
            <el-input v-model="unfreezeConfigData.thawNotifyUrl" placeholder="请输入通知地址"></el-input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="unfreezeConfigShow = false,unfreezeConfigData = {}">取 消</el-button>
          <el-button class="addBt" @click="unfreezeConfigBtn">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  getConfig,
  addWechartApp,
  delWechartApp,
  setWechartApp,
  addSaleApp,
  delSaleApp,
  setSaleApp,
  shopDetail,
  setCommonconfig,
  setAuthconfig,
  addLimits,
  delLimits,
  addSaleH5,
  delSaleH5,
  setSaleH5,
  setTradeStatus,
  delPublicConfig,
  addPublic,
  addScanCode,
  delScanCode,
  setScanCode,
  setPayIntell,
  delPayIntell,
  getProducts,
} from "@/api/user/shopManager.js";
import { appsDrop, verifiedDrop } from "@/api/common.js";
import { isNumber } from "@/utils/common.js";
export default {
  data() {
    return {
      userPayShow: false, //其它配置展示
      hasPayShow: false, //微信APP展示
      hasPayH5Show: false, //微信H5展示
      accountShow: false, //小程序收银台展示
      scanCodeShow: false, //微信扫码+展示
      appConfigs: [], //微信app配置
      cashierConfigs: [], //收银台配置
      commonConfig: {}, //通用配置
      authConfig: {}, //权限配置
      appletOptions: [], //小程序列表
      fromData: {}, //修改其它配置
      applets: [], //已部署小程序列表
      appFrom: "", //新增微信app小程序
      saleFrom: "", //新增收银台小程序
      permissionShow: false, //权限配置展示
      permissionFrom: {}, //修改权限配置
      channelDrop: [], //渠道列表
      //贸易模式产品类型可选项
      productTypeData: [
        {
          value: "wxpay",
          label: "微信支付",
        },
        {
          value: "alipay",
          label: "支付宝支付",
        },
        // {
        //   value: "unionpay",
        //   label: "银联支付",
        // },
      ],
      limitShow: false, // 限流配置
      limitFrom: {}, // 限流配置
      limitConfigs: [], // 限流配置列表
      h5Configs: [], // H5收银台
      nativeConfigs: [], //微信扫码+
      mpConfigs: {}, //公众号配置
      hasData: false, // 判断是否有公众号配置数据的变量
      publicTitle: "新增", //新增/编辑公众号弹框标题
      publicFlag: false,
      publicForm: {
        // appid: "", //输入的APPID
        // appsecret: "", //输入的秘钥
      },
      verifiedList: [], //已认证小程序列表
      limitOption: {
        1: "统一下单接口",
        2: "订单查询接口",
        3: "退款接口",
        4: "退款查询接口",
        5: "单笔代付接口",
        6: "代付查询接口",
        7: "店铺图片上传接口",
        8: "店铺信息报备接口",
        9: "店铺信息查询接口",
        10: "微信特约配置录入接口",
        11: "微信特约配置查询接口",
        12: "分账图片上传接口",
        13: "分账商户入网接口",
        14: "分账商户查询",
      },
      detail: "", //基本信息
      OFFON_EDIT:'ACCOUNT:MCH:MCHS:CONFIG/EDIT',
      intellShow:false,
      intellFrom:{
        hasRoute:true,
      },
      fineness: [
        {
          title: "常规",
          value: "0",
          disabled: false,
        },
        {
          title: "1分钟",
          value: "1",
          disabled: false,
        },
        {
          title: "3分钟",
          value: "3",
          disabled: false,
        },
        {
          title: "5分钟",
          value: "5",
          disabled: false,
        },
      ],
      finenessShow:{
        0:"常规",
        1:"1分钟",
        3:"3分钟",
        5:"5分钟",
      },
      buttonFlag:false,
      editPayIndex: null,
      payProducts:[],
      payProductsChage:[],
      oldProductId: "", //被编辑的智能路由id

      refundConfigShow:false,   
      refundConfigData: {}, 

      // 资金解冻通知配置
      unfreezeConfigShow: false,
      unfreezeConfigData: {}
    };
  },
  created() {
    this.getList();
    this.getAppletList(); //获取小程序列表
    this.getVerifiedDrop(); //获取已认证小程序列表
    this.listJurisdiction();
    this.getProductsList();
    console.log(this.$route.query,"this.$route.query")
  },
  methods: {
    getList() {
      getConfig(this.$route.query.id).then((res) => {
        if (res) {
          this.appConfigs = res.resultData.appConfigs;
          this.cashierConfigs = res.resultData.cashierConfigs;
          let obj1 = {
            body: "",
            receiptIpWhiteList: "",
            payIpWhiteList: "",
            mchChannelConfigStatus: false,
            // mchAgnpayConfigStatus: false,
            mchTreminalStatus:false,
            limitPayConfigStatus: false,
            tradeAccountStatus:false,
            refundRemittanceStatus: false,
            refundRemittanceUrl: "",
          };
          this.commonConfig = res.resultData.commonConfig ? res.resultData.commonConfig: obj1;
          let obj2 = {
            applyStoreStatus: false,
            storeMchChannelId: "",
            allocFundStatus: false,
            allocFundMchChannelId: "",
            freeTrialStatus: false,
            filingStatus: false,
          };
          // 拿到 贸易模式开关状态
          if (res.resultData.authConfig.tradeStatus == 0) {
            res.resultData.authConfig.tradeType = false;
          } else {
            res.resultData.authConfig.tradeType = true;
          }

          this.authConfig = res.resultData.authConfig ? res.resultData.authConfig : obj2;

          this.limitConfigs = res.resultData.limitConfigs;
          this.h5Configs = res.resultData.h5Configs;
          this.nativeConfigs = res.resultData.nativeConfigs;
          this.mpConfigs = res.resultData.mpConfigs;

          var isNull = res.resultData.mpConfigs == null;
          console.log("公众号配置数据", this.mpConfigs);

          if (isNull) {
            this.hasData = true;
          } else {
            this.hasData = false;
          }
        }
      });
      shopDetail(this.$route.query.id).then((res) => {
        if (res) {
          this.channelDrop = res.resultData.mchChannels;
          this.detail = res.resultData.mchDetail;
        }
      });
    },

    // 修改退汇启用状态
    refundConfigStatus(value) {
      let text = value.refundRemittanceStatus
        ? `是否确认启用${value.refundRemittanceUrl}通知地址`
        : `是否确认关闭${value.refundRemittanceUrl}通知地址`;
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setCommonconfig(this.$route.query.id, this.commonConfig).then((res) => {
            if (!res) {
              if (value.refundRemittanceStatus) {
                this.commonConfig.refundRemittanceStatus = this.commonConfig.refundRemittanceStatus ? false : true;
              }
            }
          });
        })
        .catch(() => {
          this.$message({type: "info",message: "已取消操作",});
          value.refundRemittanceStatus = value.refundRemittanceStatus ? false : true;
        });
    },

    // 修改退汇通用地址
    refundConfigEdit(value){
      this.refundConfigData = JSON.parse(JSON.stringify(value));
      this.refundConfigShow = true; 
    },

    refundConfigBtn(){
      if(!this.refundConfigData.refundRemittanceUrl){
        return this.$message({type: "error",message: "通知地址必填",});
      }
      setCommonconfig(this.$route.query.id, this.refundConfigData).then((res) => {
            if (res.resultStatus) {
              this.refundConfigShow = false; 
              this.getList();
            }
      });
    },

    // 修改资金解冻启用状态
    unfreezeConfigStatus(value) {
      let text = value.thawNotifyStatus
        ? `是否确认启用${value.thawNotifyUrl}通知地址`
        : `是否确认关闭${value.thawNotifyUrl}通知地址`;
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setCommonconfig(this.$route.query.id, this.commonConfig).then((res) => {
            if (!res) {
              if (value.thawNotifyStatus) {
                this.commonConfig.thawNotifyStatus = this.commonConfig.refundRemittanceStatus ? false : true;
              }
            }
          });
        })
        .catch(() => {
          this.$message({type: "info",message: "已取消操作",});
          value.thawNotifyStatus = value.thawNotifyStatus ? false : true;
        });
    },

    // 修改资金解冻通用地址
    unfreezeConfigEdit(value){
      this.unfreezeConfigData = JSON.parse(JSON.stringify(value));
      this.unfreezeConfigShow = true; 
    },

    unfreezeConfigBtn(){
      if(!this.unfreezeConfigData.thawNotifyUrl){
        return this.$message({ type: "error", message: "通知地址必填" });
      }
      setCommonconfig(this.$route.query.id, this.unfreezeConfigData).then((res) => {
        if (res.resultStatus) {
          this.unfreezeConfigShow = false; 
          this.getList();
        }
      });
    },

    // 智能路由-新增
    intellRouteAdd(){
      this.intellShow = true;
      this.editPayIndex = null;
      this.intellFrom = {};
      this.payProductsChage.forEach((val) => {
        val.disabled = false;
      });
      this.payProducts.forEach((v) => {
        this.payProductsChage.forEach((val) => {
          if (v.productId == val.productId) {
            val.disabled = true;
          }
        });
      });
    },
    // 智能路由-确认新增
    addintellFrom(){
      let data = JSON.parse(JSON.stringify(this.intellFrom));
      if(!data.productId){
        this.$message.error("产品类型必选");
        return;
      }
      if (!data.routeThreshold && data.routeThreshold !== 0) {
        this.$message.error("路由阈值必填");
        return false;
      } 
      if (data.routeThreshold<0 || data.routeThreshold>100 || !isNumber(data.routeThreshold)) {
        this.$message.error("路由阈值不合法(请输入0-100正整数)");
        return false;
      }
      if(!data.riskGranularity){
        this.$message.error("路由粒度必选");
        return;
      }
      if(!data.routeNum && data.routeNum !== 0){
        this.$message.error("最小风控订单数必填");
        return;
      }
      if (data.routeNum < 0 || !/^[0-9]*$/.test(data.routeNum)) {
        this.$message.error("最小风控订单数至少为0,且为整数");
        return;
      }
      if(!data.tradeNum){
        this.$message.error("交易商户数量必填");
        return;
      }
      if (data.tradeNum <= 0 || !/^[0-9]*$/.test(data.tradeNum)) {
        this.$message.error("交易商户数量大于0,且为整数");
        return;
      }

      let mchChannelId = data.mchChannelId;

      let productId =
        this.editPayIndex == null ? data.productId : this.oldProductId;

      data.routeThreshold =  parseInt(data.routeThreshold);
      data.routeNum =  parseInt(data.routeNum);
      data.tradeNum =  parseInt(data.tradeNum);
      data.riskGranularity =  parseInt(data.riskGranularity);

      if(this.intellFrom.riskGranularity == "1分钟" || this.intellFrom.riskGranularity == 1){
        data.riskGranularity = 1
      }else if(this.intellFrom.riskGranularity == "3分钟" || this.intellFrom.riskGranularity == 3){
        data.riskGranularity = 3
      }else if(this.intellFrom.riskGranularity == "5分钟" || this.intellFrom.riskGranularity == 5){
        data.riskGranularity = 5
      }else{
        data.riskGranularity = 0
      }

      console.log(data,"ooooooooo")

      this.buttonFlag = true;
      setPayIntell(
        this.$route.query.id,
        mchChannelId,
        productId,
        data
      ).then((res) => {
        if (res.resultStatus) {
          if (this.editPayIndex == null) {
            this.$message.success("新增智能路由配置成功");
          } else {
            this.$message.success("编辑智能路由配置成功");
          }
          this.getProductsList();
          this.intellShow = false;
          this.editPayIndex = null;
        }
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
      });

    },

    //智能路由-删除
    delPayIntell(data,i) {
      let productId = data.productId;
      let mchChannelId = data.mchChannelId;
      this.$confirm("此操作将删除该智能路由, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delPayIntell(
            this.$route.query.id,
            mchChannelId,
            productId,
            data
          ).then((res) => {
            if (res.resultStatus) {
              this.getProductsList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //智能路由-编辑
    editPayIntell(value) {
      this.intellFrom =  JSON.parse(JSON.stringify(value));
      this.oldProductId = this.intellFrom.productId;
      this.editPayIndex = 1;

      if(value.riskGranularity == 1){
        this.intellFrom.riskGranularity = "1分钟"
      }else if(value.riskGranularity == 3){
        this.intellFrom.riskGranularity = "3分钟"
      }else if(value.riskGranularity == 5){
        this.intellFrom.riskGranularity = "5分钟"
      }else{
        this.intellFrom.riskGranularity = "常规"
      }

      this.intellShow = true;

      this.payProductsChage.forEach((val) => {
        val.disabled = true;
      });

    },

    //智能路由-产品类型-下拉数据改变
    productIdChange(){
      // if(this.intellFrom.productId != ''){
          this.payProductsChage.forEach(item=>{
            if(item.productId == this.intellFrom.productId){
              this.intellFrom = JSON.parse(JSON.stringify(item));

              if(this.editPayIndex == null){
                this.intellFrom.tradeNum = null; 
                this.intellFrom.routeThreshold = null;   
                this.intellFrom.routeNum = null;
              }
 
              if(item.riskGranularity == 1){
                this.intellFrom.riskGranularity = "1分钟"
              }else if(item.riskGranularity == 3){
                this.intellFrom.riskGranularity = "3分钟"
              }else if(item.riskGranularity == 5){
                this.intellFrom.riskGranularity = "5分钟"
              }else{
                this.intellFrom.riskGranularity = "常规"
              }
            }
          })
      // }
    },

    //智能路由-产品类型-下拉数据
    getProductsList() {
      this.payProducts = [];
      this.payProductsChage = [];
      getProducts(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          let data = res.resultData.payProducts;
          data.forEach(item=>{
            if(item.hasRoute == true){
              this.payProducts.push(item)
            }
            // if(item.routeStatus == true){
              this.payProductsChage.push(item)
            // }
          })
        }
      })
    },

    //修改其它配置页面展示
    setOtherShow() {
      this.fromData = JSON.parse(JSON.stringify(this.commonConfig));
      this.fromData.receiptIpWhiteList = this.fromData.receiptIpWhiteList
        ? this.fromData.receiptIpWhiteList.split(";")
        : [""];
      this.fromData.payIpWhiteList = this.fromData.payIpWhiteList
        ? this.fromData.payIpWhiteList.split(";")
        : [""];
      this.userPayShow = true;
    },
    // 修改其它配置
    setCommonconfig() {
      let error = 0;
      let data = JSON.parse(JSON.stringify(this.fromData));
      data.receiptIpWhiteList = data.receiptIpWhiteList.filter(
        (v) => v != false
      );
      data.payIpWhiteList = data.payIpWhiteList.filter((v) => v != false);
      data.receiptIpWhiteList.forEach((v, i) => {
        if (
          !/^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/.test(
            v
          )
        ) {
          error++;
        }
      });
      data.payIpWhiteList.forEach((v, i) => {
        if (
          !/^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/.test(
            v
          )
        ) {
          error++;
        }
      });
      if (error > 0) {
        this.$message.error("有错误的IP地址");
        return;
      }
      data.receiptIpWhiteList = data.receiptIpWhiteList.join(";");
      data.payIpWhiteList = data.payIpWhiteList.join(";");
      setCommonconfig(this.$route.query.id, data).then((res) => {
        if (res) {
          this.$message.success("保存成功");
          this.getList();
          this.userPayShow = false;
        }
      });
    },
    // 通用配置状态改变
    setCommonconfigStatus(value) {
      setCommonconfig(this.$route.query.id, this.commonConfig).then((res) => {
        if (!res) {
          if (value.mchChannelConfigStatus) {
            this.commonConfig.mchChannelConfigStatus = this.commonConfig
              .mchChannelConfigStatus
              ? false
              : true;
          }

          // if (value.mchAgnpayConfigStatus) {
          //   this.commonConfig.mchAgnpayConfigStatus = this.commonConfig
          //     .mchAgnpayConfigStatus
          //     ? false
          //     : true;
          // }

          if (value.mchTreminalStatus) {
            this.commonConfig.mchTreminalStatus = this.commonConfig
              .mchTreminalStatus
              ? false
              : true;
          }

          if (value.limitPayConfigStatus) {
            this.commonConfig.limitPayConfigStatus = this.commonConfig
              .limitPayConfigStatus
              ? false
              : true;
          }

          if (value.tradeAccountStatus) {
            this.commonConfig.tradeAccountStatus = this.commonConfig
              .tradeAccountStatus
              ? false
              : true;
          }

        }
      });
    },
    // 权限配置展示
    setAuthconfigShow() {
      this.permissionShow = true;
      this.permissionFrom = JSON.parse(JSON.stringify(this.authConfig));
    },
    // 修改权限配置
    setAuthconfig() {
      let data = JSON.parse(JSON.stringify(this.permissionFrom));
      if (data.applyStoreStatus && !data.storeMchChannelId) {
        this.$message.error("店铺关联渠道必选");
        return;
      }
      if (data.allocFundStatus && !data.allocFundMchChannelId) {
        this.$message.error("分账关联渠道必选");
        return;
      }
      console.log("修改权限提交的数据", data);
      setAuthconfig(this.$route.query.id, data).then((res) => {
        if (res) {
          this.$message.success("保存成功");
          this.getList();
          this.permissionShow = false;
        }
      });
    },
    //修改权限配置-贸易模式开关状态
    changeTradeType(e) {
      // console.log("修改权限配置-贸易模式开关状态", e);
      // return;
      let data = {};
      if (e) {
        //打开贸易模式开关时
        //  &&!this.authConfig.ipStatus
        if (
          this.authConfig.applyStoreStatus &&
          this.authConfig.freeTrialStatus
        ) {
          data.tradeStatus = 1;
        } else {
          this.authConfig.tradeType = false;
          this.authConfig.tradeStatus = 0;
          if (!this.authConfig.applyStoreStatus) {
            this.$message.error("店铺报备权限已关闭");
            return;
          }
          if (!this.authConfig.freeTrialStatus) {
            this.$message.error("店铺免审模式已关闭");
            return;
          }
          // 已弃用
          // if (this.authConfig.ipStatus) {
          //   this.$message.error("IP制导已打开");
          //   return;
          // }
        }
      } else {
        //关闭贸易模式时
        data.tradeStatus = 0;
      }
      setTradeStatus(this.$route.query.id, data).then((res) => {
        if (res.resultStatus==true) {
          this.$message.success("设置成功");
          // this.authConfig.tradeType = true;
          // this.authConfig.tradeStatus = 1;
        }else{
          data.tradeStatus = 0;
          this.authConfig.tradeType = false;
        }
        console.log("打开贸易模式开关结果", res);
      });
    },
    // 修改权限配置状态改变  authConfig.tradeType
    //  value.applyStoreStatus 店铺报备权限开关
    //  value.freeTrialStatus 店铺免审模式开关

    setAuthconfigStatus(type, value) {
      console.log("操作开关", value.applyStoreStatus);
      console.log("操作开关", type);

      if (type == 1 || type == 2) {
        // this.$message.error("操作店铺报备权限开关");
        if (this.authConfig.tradeType) {
          this.$message.error("请先关闭贸易模式");

          //操作店铺权限报备开关时
          if (!value.freeTrialStatus) {
            //关闭店铺免审模式开关时 判断贸易模式开关是否为关闭状态
            this.authConfig.freeTrialStatus = true;
          }
          //关闭店铺报备开关时 判断贸易模式开关是否为关闭状态
          if (!value.applyStoreStatus) {
            this.authConfig.applyStoreStatus = true;
          }
          return;
        }
      }

      console.log("提交的数据", this.authConfig);

      // return

      if (value.applyStoreStatus && !this.authConfig.storeMchChannelId) {
        this.$message.error("请先设置店铺关联渠道");
        this.authConfig.applyStoreStatus = this.authConfig.applyStoreStatus
          ? false
          : true;
        return;
      }

      if (value.allocFundStatus && !this.authConfig.allocFundMchChannelId) {
        this.$message.error("请先设置分账关联渠道");
        this.authConfig.allocFundStatus = this.authConfig.allocFundStatus
          ? false
          : true;
        return;
      }

      setAuthconfig(this.$route.query.id, this.authConfig).then((res) => {
        if (!res) {
          if (value.applyStoreStatus) {
            this.authConfig.applyStoreStatus = this.authConfig.applyStoreStatus
              ? false
              : true;
          }
          if (value.allocFundStatus) {
            this.authConfig.allocFundStatus = this.authConfig.allocFundStatus
              ? false
              : true;
          }
          if (value.freeTrialStatus) {
            this.authConfig.freeTrialStatus = this.authConfig.freeTrialStatus
              ? false
              : true;
          }
          if (value.filingStatus) {
            this.authConfig.filingStatus = this.authConfig.filingStatus
              ? false
              : true;
          }
        }
      });
    },
    // 获取小程序列表
    getAppletList() {
      appsDrop().then((res) => {
        if (res) {
          this.applets = res.resultData;
        }
      });
    },
    // 获取已认证小程序列表
    getVerifiedDrop() {
      verifiedDrop().then((res) => {
        if (res) {
          this.verifiedList = res.resultData;
        }
      });
    },
    //增微信APP展示
    addWechartAppShow() {
      this.hasPayShow = true;
      this.appFrom = "";
    },
    //新增微信APP
    addWechartApp() {
      let data = this.appFrom;
      if (!data) {
        this.$message.error("小程序必选");
        return;
      }
      // data = data.split("|")[0]
      addWechartApp(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getList();
          this.hasPayShow = false;
        }
      });
    },
    // 删除微信APP
    delWechartApp(smallId) {
      this.$confirm("此操作将删除该微信APP配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delWechartApp(this.$route.query.id, smallId).then((res) => {
            if (res) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 修改微信APP状态
    setWechartApp(value) {
      let text = value.enabledStatus
        ? `是否确认启用${value.appName}小程序`
        : `是否确认关闭${value.appName}小程序`;
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            mchId: this.$route.query.id,
            smallId: value.smallId,
            enabledStatus: value.enabledStatus,
          };
          setWechartApp(data).then((res) => {
            if (res) {
              this.getList();
              this.appConfigs
                .filter((obj) => obj.smallId != value.smallId)
                .forEach((result) => {
                  if (value.enabledStatus) {
                    let closeData = {
                      mchId: this.$route.query.id,
                      smallId: result.smallId,
                      enabledStatus: false,
                    };
                    setWechartApp(closeData).then(() => {
                      this.getList();
                    });
                  }
                });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          value.enabledStatus = value.enabledStatus ? false : true;
        });
    },
    // 新增收银台APP展示
    addSaleAppShow() {
      this.accountShow = true;
      this.saleFrom = "";
    },
    // 新增收银台APP
    addSaleApp() {
      let data = this.saleFrom;
      if (!data) {
        this.$message.error("小程序必选");
        return;
      }
      // data = data.split("|")[0];
      addSaleApp(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getList();
          this.accountShow = false;
        }
      });
    },
    // 删除收银台APP
    delSaleApp(smallId) {
      this.$confirm("此操作将删除该收银台APP配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delSaleApp(this.$route.query.id, smallId).then((res) => {
            if (res) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 修改收银台APP状态
    setSaleApp(value) {
      let text = value.enabledStatus
        ? `是否确认启用${value.appName}小程序`
        : `是否确认关闭${value.appName}小程序`;
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            mchId: this.$route.query.id,
            smallId: value.smallId,
            enabledStatus: value.enabledStatus,
          };
          setSaleApp(data).then((res) => {
            if (res) {
              this.getList();
              this.cashierConfigs
                .filter((obj) => obj.smallId != value.smallId)
                .forEach((result) => {
                  if (value.enabledStatus) {
                    let closeData = {
                      mchId: this.$route.query.id,
                      smallId: result.smallId,
                      enabledStatus: false,
                    };
                    setSaleApp(closeData).then(() => {
                      this.getList();
                    });
                  }
                });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          value.enabledStatus = value.enabledStatus ? false : true;
        });
    },
    // 新增H5收银台展示
    addSaleH5Show() {
      this.hasPayH5Show = true;
      this.appFrom = "";
    },
    // 新增H5收银台
    addSaleH5() {
      let data = this.appFrom;
      if (!data) {
        this.$message.error("小程序必选");
        return;
      }
      // data = data.split("|")[0];
      addSaleH5(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getList();
          this.hasPayH5Show = false;
        }
      });
    },
    // 删除H5收银台
    delSaleH5(smallId) {
      this.$confirm("此操作将删除该H5收银台配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delSaleH5(this.$route.query.id, smallId).then((res) => {
            if (res) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 修改H5收银台状态
    setSaleH5(value) {
      let text = value.enabledStatus
        ? `是否确认启用${value.appName}小程序`
        : `是否确认关闭${value.appName}小程序`;
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            mchId: this.$route.query.id,
            smallId: value.smallId,
            enabledStatus: value.enabledStatus,
          };
          setSaleH5(data).then((res) => {
            if (res) {
              this.getList();
              this.h5Configs
                .filter((obj) => obj.smallId != value.smallId)
                .forEach((result) => {
                  if (value.enabledStatus) {
                    let closeData = {
                      mchId: this.$route.query.id,
                      smallId: result.smallId,
                      enabledStatus: false,
                    };
                    setSaleH5(closeData).then(() => {
                      this.getList();
                    });
                  }
                });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          value.enabledStatus = value.enabledStatus ? false : true;
        });
    },
    // app选择改变
    appChange(value) {
      let obj = this.applets.find((v) => v.smallId == value);
      this.appFrom = obj.appName + "|" + obj.principalName;
    },
    // sale选择
    saleChange(value) {
      let obj = this.applets.find((v) => v.smallId == value);
      this.saleFrom = obj.appName + "|" + obj.principalName;
    },
    // 新增限流配置展示
    addLimitShow() {
      this.limitFrom = {
        rule: "",
        interfaceName: "",
        frequency: "",
      };
      this.limitShow = true;
    },
    // 新增限流配置
    addLimit() {
      let data = this.limitFrom;
      if (!data.interfaceName) {
        this.$message.error("接口必选");
        return;
      }
      if (!data.rule) {
        this.$message.error("限流规则必选");
        return;
      }
      if (String(data.frequency)=='') {
        this.$message.error("限流阀值必填");
        return;
      } else {
        if (!isNumber(data.frequency)) {
          this.$message.error("限流阈值不合法(请输入0-100正整数)");
          return ;
        }
      }
      data.frequency=Number(data.frequency)
      addLimits(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getList();
          this.limitShow = false;
        }
      });
    },
    // 修改限流配置
    editLimit(obj) {
      this.limitFrom = {
        id: obj.id,
        rule: obj.rule + "",
        interfaceName: obj.interfaceName + "",
        frequency: obj.frequency,
        mchId: obj.mchId,
      };
      this.limitShow = true;
    },
    // 删除限流配置
    delLimit(id) {
      this.$confirm("此操作将删除该限流配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delLimits(this.$route.query.id, id).then((res) => {
            if (res) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 新增/编辑公众号配置
    openPublic(val, obj) {
      for (var i in this.publicForm) {
        this.publicForm[i] = "";
      }
      if (val == 2) {
        //点击编辑公众号配置时
        this.publicForm = JSON.parse(JSON.stringify(obj));
        this.publicTitle = "编辑";
      } else {
        this.publicTitle = "新增";
      }
      this.publicFlag = true;
    },
    // 删除公众号配置
    delPublic(obj) {
      // console.log("删除公众号配置",obj)
      this.$confirm("此操作将删除该公众号配置 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delPublicConfig(obj.id).then((res) => {
            if (res.resultStatus) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 校验新增公众号配置参数
    rules() {
      const data = JSON.parse(JSON.stringify(this.publicForm));

      if (!data.appid && !data.appsecret && !data.mpappid) {
        this.$message.error("请输入公众号APPID和密钥或者选择第三方小程序");
        return false;
      }
      if (data.appid) {
        if (!data.appsecret) {
          this.$message.error("请输入公众号密钥");
          return false;
        }
      }
      if (data.appsecret) {
        if (!data.appid) {
          this.$message.error("请输入公众号APPID");
          return false;
        }
      }
      return true;
    },
    // 下拉框清除选中的第三方小程序
    clearVerified() {
      this.publicForm.mpappid = "";
    },
    // 确定新增公众号配置
    confirmPublic() {
      let id = "";
      if (this.rules()) {
        if (this.publicTitle == "新增") {
          id = 0;
        } else {
          console.log("编辑时", this.publicForm);
          id = this.publicForm.id;
        }
        // return;
        let params = {
          appid: this.publicForm.appid,
          id,
          appsecret: this.publicForm.appsecret,
          mpappid: this.publicForm.mpappid,
        };
        addPublic(this.$route.query.id, params).then((res) => {
          if (res.resultStatus) {
            this.publicFlag = false;
            this.getList();
            if (this.publicTitle == "新增") {
              this.$message.success("新增成功");
            } else {
              this.$message.success("编辑成功");
            }
          }
        });
      }
    },

    // 新增微信扫码+展示
    addWechartScanShow() {
      this.scanCodeShow = true;
      this.appFrom = "";
    },
    // 点击确定新增微信扫码+
    confirmScanCode() {
      let data = this.appFrom;
      if (!data) {
        this.$message.error("小程序必选aa");
        return;
      }
      // data = data.split("|")[0]
      addScanCode(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getList();
          this.scanCodeShow = false;
        }
      });
    },

    // 删除微信扫码+
    delScanCode(smallId) {
      this.$confirm("此操作将删除该微信扫码+配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delScanCode(this.$route.query.id, smallId).then((res) => {
            if (res) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 修改微信扫码+ 状态
    setScanCode(value) {
      let text = value.enabledStatus
        ? `是否确认启用${value.appName}小程序`
        : `是否确认关闭${value.appName}小程序`;
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            mchId: this.$route.query.id,
            smallId: value.smallId,
            enabledStatus: value.enabledStatus,
          };
          setScanCode(data).then((res) => {
            if (res.resultStatus) {
              this.getList();
              this.nativeConfigs
                .filter((obj) => obj.smallId != value.smallId)
                .forEach((result) => {
                  if (value.enabledStatus) {
                    let closeData = {
                      mchId: this.$route.query.id,
                      smallId: result.smallId,
                      enabledStatus: false,
                    };
                    setScanCode(closeData).then(() => {
                      this.getList();
                    });
                  }
                });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          value.enabledStatus = value.enabledStatus ? false : true;
        });
    },
    // 跳转页面
    toUrl(name, query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
            this.OFFON_EDIT = 'ACCOUNT:MCH:MCHS:CONFIG/EDIT';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:MCH:MCHS:CONFIG/EDIT') !== -1 ){
            this.OFFON_EDIT = 'ACCOUNT:MCH:MCHS:CONFIG/EDIT';
          }else{
            this.OFFON_EDIT = "";
          }
        }
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";

.tab1 {
  min-height: auto !important;
}

.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}

.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}


.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
</style>
<style>
.overAccount_ul .el-input-group__append {
  padding: 0 10px;
}
</style>
