<template>
  <div id="mianBigBox" class="clear" :class="isActiveHead==true ? 'hideSidebar' : 'openSidebar'">
    <div class="left_nav">
      <el-container>
        <el-aside :style="{ width: isCollapse ? 'auto' : '202px' }">
          <el-menu
            :collapse-transition="false"
            :unique-opened="true"
            :router="true"
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            :collapse="isCollapse">
            <el-submenu v-auth="'OPERATION:ORDERS'" index="1">
              <template slot="title">
                <span slot="title">订单管理</span>
              </template>
              <el-menu-item v-auth="'OPERATION:ORDERS:TRADE'" index="/operation/manage_jydd">交易订单管理</el-menu-item>
              <el-menu-item v-auth="'OPERATION:ORDERS:REFUND'" index="/operation/manage_tkdd">退款订单管理</el-menu-item>
              <el-menu-item v-auth="'OPERATION:ORDERS:PAY'" index="/operation/manage_dfdd">代付订单管理</el-menu-item>
              <el-menu-item v-auth="'OPERATION:ORDERS:ALLOC'" index="/operation/manage_fzjy">分账交易订单管理</el-menu-item>
              <el-menu-item v-auth="'OPERATION:ORDERS:ALLOCPAY'" index="/operation/manage_fzdf">分账代付订单管理</el-menu-item>
              <el-menu-item v-auth="'OPERATION:ORDERS:REFUNDJS'" index="/operation/manage_fzjs">分账结算订单管理</el-menu-item>
              <el-menu-item v-auth="'OPERATION:ORDERS:TRANSFERZZ'"
                index="/operation/manage_fzzz">分账转账订单管理</el-menu-item>
            </el-submenu>

            <el-submenu v-auth="'OPERATION:NOTICES'" index="2">
              <template slot="title">
                <span slot="title">通知管理</span>
              </template>
              <el-menu-item v-auth="'OPERATION:NOTICES:ORDER'" index="/operation/deal_jytz">交易通知查询</el-menu-item>
              <el-menu-item v-auth="'OPERATION:NOTICE:REFUND'" index="/operation/refund_tktz">退款通知查询</el-menu-item>
              <el-menu-item v-auth="'OPERATION:NOTICES:PAY'" index="/operation/applyfor_dftz">付款通知查询</el-menu-item>
              <el-menu-item v-auth="'OPERATION:NOTICES:FUNDUNFREEZE'"
                index="/operation/fund_unfreeze">资金解冻通知查询</el-menu-item>
              <el-menu-item v-auth="'OPERATION:NOTICES:ALLOC'" index="/operation/account_fztz">分账交易通知查询</el-menu-item>
              <el-menu-item v-auth="'OPERATION:NOTICES:ALLOCPAY'"
                index="/operation/account_fzdftz">分账代付通知查询</el-menu-item>
              <el-menu-item v-auth="'OPERATION:NOTICES:REFUNDJS'" index="/operation/refund_jstz">分账结算通知查询</el-menu-item>
              <el-menu-item v-auth="'OPERATION:NOTICES:GUARANTEE'"
                index="/operation/guarantee_dbtz">分账担保通知查询</el-menu-item>
              <el-menu-item v-auth="'OPERATION:NOTICES:TRANSFERZZ'"
                index="/operation/transfer_zztz">分账转账通知查询</el-menu-item>
            </el-submenu>
            <el-submenu v-auth="'OPERATION:RECEIPT_TEST'" index="3">
              <template slot="title">
                <span slot="title">测试管理</span>
              </template>
              <el-menu-item v-auth="'SYSTEM:RECEIPT_TEST:PAY'" index="/operation/test_result">收款测试</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
      </el-container>
    </div>
    <div class="right_con">
      <div class="right_con_nav">
        <el-tabs v-model="activeTab" closable @tab-click="clickTab" @tab-remove="removeTab">
          <el-tab-pane v-for="item in tabs" :key="item.fullPath" :label="item.title" :name="item.fullPath">
          </el-tab-pane>
        </el-tabs>
        <div class="clearBt" @click="clearTab">
          <i class="el-icon-delete"></i>
        </div>
      </div>
      <div class="right_con_con">
        <!-- <div class="title" v-if="$route.meta.title">{{$route.meta.title}}</div> -->
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeTab: "",
      tabs: [],
      isCollapse: null,
      isActiveHead:this.$store.state.isActiveHead,
    };
  },
  created() {
    this.tabs = JSON.parse(sessionStorage.getItem("tabs"));
    this.activeTab = sessionStorage.getItem("active_tab");
    var localValue;
    this.bus.$on('isActiveHead', res => {
        localValue = res;//当bus触发时，这里生效，将sendNavText值修改了，但是，当点开该页面路由组件的时候，data函数初始化了sendNavText值
        this.$store.state.isActiveHead = res; //(1)将该值存在store中
        this.isActiveHead = res;
    })
  },
  watch: {
    $route: {
      handler: function () {
        this.tabs = JSON.parse(sessionStorage.getItem("tabs"));
        this.activeTab = sessionStorage.getItem("active_tab");
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    clickTab(tab) {
      this.$router.push(tab.name);
    },
    removeTab(targetName) {
      if (this.tabs.length == 1) {
        return;
      }
      this.tabs.forEach((v, i) => {
        if (v.fullPath === targetName) {
          if (targetName === this.activeTab) {
            if (i !== 0) {
              this.tabs.splice(i, 1);
              sessionStorage.setItem("active_tab", this.tabs[i - 1].fullPath);
              this.$router.push(this.tabs[i - 1].fullPath);
            } else {
              this.tabs.splice(i, 1);
              sessionStorage.setItem("active_tab", this.tabs[0].fullPath);
              this.$router.push(this.tabs[0].fullPath);
            }
          } else {
            this.tabs.splice(i, 1);
          }
          sessionStorage.setItem("tabs", JSON.stringify(this.tabs));
          return;
        }
      });
    },
    // 清除tab
    clearTab() {
      this.$confirm("确认清空吗?", "清空", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tabs = [
            {
              title: this.$route.meta.tab,
              fullPath: this.$route.fullPath,
            },
          ];
          sessionStorage.setItem("tabs", JSON.stringify(this.tabs));
          sessionStorage.setItem("active_tab", this.tabs[0].fullPath);
          this.$message({
            type: "success",
            message: "清空成功",
            duration: 2000,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消清空",
          });
        });
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/index.css';

.no-tab>>>.tab1 {
  overflow-x: hidden;
}

.no-tab>>>.tab1 td {
  word-break: break-all;
  white-space: pre-wrap;
}
</style>>