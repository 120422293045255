<template>
  <div class="login">
    <div class="loginBox">
      <div class="leftBox">
        <div class="logo">
          <div class="logoImg"></div>
        </div>
      </div>
      <div class="rightBox">
        <div class="form">
          <h1>欢迎来到管理后台。</h1>
          <h2>账号</h2>
          <el-input
            class="inputs"
            v-model="formData.username"
            maxlength="16"
            placeholder="管理后台账号"></el-input>
          <h2>密码</h2>
          <el-input
            class="inputs"
            show-password
            v-model="formData.password"
            placeholder="密码"
            :readonly='readonly'
            @focus='handlerIptClick'
            auto-complete="new-password"></el-input>
          <h2>验证码</h2>
          <!-- <el-input class="inputs min" v-model="formData.randCode" maxlength="4" placeholder="验证码"></el-input> -->
          <el-input
            class="inputs min"
            v-model="formData.validateCode"
            placeholder="验证码"></el-input>
          <!-- <span class="img_code">
            <img :src="imageCode" @click="reImageCode" alt="">
          </span> -->
          <span class="img_code" @click="getCode(true)">
            {{ timeOut1 == 0 ? "获取验证码" : `倒计时${timeOut1}s` }}
          </span>
          <div class="button" @click="login">登录</div>
          <div class="setUser">
            <div>
              <el-checkbox v-model="checkUser">记住账号</el-checkbox>
            </div>
            <div @click="setPass">忘记密码?</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="忘记密码" :visible.sync="passShow" custom-class="loginBoxDoalog"
      v-alterELDialogMarginTop="{marginTop:'0vh'}">
      <div class="loginBox loginModel">
        <div class="form">
          <h2 style="margin-top:0px;"><i>*</i>账户名</h2>
          <el-input
            v-model="setPsssform.username"
            maxlength="16"
            placeholder="输入账户名"
            class="inputs"></el-input>
          <!-- <div class="overAccount_ul_title"><i>*</i>手机号</div>
					<el-input v-model="phone" maxlength="11" placeholder="输入手机号" class="width100"></el-input> -->
          <h2><i>*</i>短信验证码</h2>
          <el-input
            v-model="setPsssform.validateCode"
            placeholder="输入验证码"
            class="inputs min"></el-input>
          <span class="getCode" @click="getCode(false)">{{
            timeOut == 0 ? "获取验证码" : `倒计时${timeOut}s`
            }}</span>
          <h2><i>*</i>新密码</h2>
          <el-input
            v-model="setPsssform.newPassword"
            show-password
            placeholder="输入新密码"
            class="inputs"></el-input>
          <h2><i>*</i>重复新密码</h2>
          <el-input
            v-model="setPsssform.rePassword"
            show-password
            placeholder="重复输入新密码"
            class="inputs"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="passShow = false">取 消</el-button>
        <el-button class="addBt" @click="editPass">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "@../../../src/utils/flexible"
import { isLogin, resetpwd, validatecode } from "@/api/login.js";
import md5 from "js-md5";
import { URL_API } from "@/utils/apiUrl.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      readonly:true,
      formData: {
        username: "", //用户名
        password: "", //密码
        validateCode: "", //验证码
      },
      setPsssform: {
        username: "",
        validateCode: "",
        newPassword: "",
        rePassword: "",
      },
      phone: "",
      imageCode: URL_API.codeUrl,
      passShow: false,
      timeOut1: 0,
      timeOut: 0,
      codeShow1: "",
      codeShow: "",

      checkUser: false,
    };
  },
  created() {
    if (localStorage.getItem("username")) {
      this.formData.username = localStorage.getItem("username");
      this.checkUser = true;
    }
    this.reImageCode();
    let that = this;
    document.onkeydown = function (e) {
      let key = window.event.keyCode;
      if (key === 13 && that.$route.name === "login") {
        that.login(); //方法
      }
    };
  },
  methods: {
    ...mapMutations({
      setDeal: "trading_setDeal",
      setTest: "operation_setTest",
      setTradingRefund:"trading_setRefund",
      setTradingRefundSh:"trading_setRefund_sh",
      trading_setPaidquery:"trading_setPaidquery",
      trading_setAccount:"trading_setAccount",
      trading_setAccount_tk:"trading_setAccount_tk",
      trading_setAccount_df:"trading_setAccount_df",
      trading_setRefund_sg:"trading_setRefund_sg",
      trading_setGuarantee:"trading_setGuarantee",
      trading_setTransfer_zz:"trading_setTransfer_zz",
      trading_setCustoms:'trading_setCustoms',
      data_setSdfx:'data_setSdfx',
      operation_setJydd:'operation_setJydd',
      operation_setTkdd:"operation_setTkdd",
      operation_setDfdd:"operation_setDfdd",
      operation_setFzjy:"operation_setFzjy",
      operation_setFzdf:"operation_setFzdf",
      operation_setFzjs:"operation_setFzjs",
      operation_setFzzz:"operation_setFzzz",
      operation_setDeal:"operation_setDeal",
      operation_setRefund:"operation_setRefund",
      operation_setApplyfor:"operation_setApplyfor",
      operation_setAccount:"operation_setAccount",
      operation_setAccountpay:"operation_setAccountpay",
      operation_setRefundJS:"operation_setRefundJS",
      operation_setGuarantee:"operation_setGuarantee",
      operation_setTransferZZ:"operation_setTransferZZ",
      system_setLogsQuery:"system_setLogsQuery",
      trading_setRefund_sgjssh:"trading_setRefund_sgjssh",
      operation_setFundUnfreeze:"operation_setFundUnfreeze",
      
      //显示前一天时间
      trading_setAutomatic:"trading_setAutomatic",
      data_setShtj:"data_setShtj",
      data_setDptj:"data_setDptj",
      data_setJghz:"data_setJghz",
      data_setQdhz:"data_setQdhz",
      data_setShhz:"data_setShhz",
      data_setDlshz:"data_setDlshz",
      bill_setSearchQuery:"bill_setSearchQuery",
      bill_setCollecthQuery:"bill_setCollecthQuery",
      platform_setoverview_jygk:"platform_setoverview_jygk",
      platform_setoverview_sygk:"platform_setoverview_sygk",
      data_setnalysis_ipfx:"data_setnalysis_ipfx",

      //
      trading_setManage:"trading_setManage",
      trading_setAccount_list:"trading_setAccount_list",
      user_setOrganQuery:"user_setOrganQuery",
      user_setBusinessQuery:"user_setBusinessQuery",
      user_setAgentsQuery:"user_setAgentsQuery",
      user_setSubaccountQuery:"user_setSubaccountQuery",
      user_setLedgerQuery:"user_setLedgerQuery",
      user_setAddLedger:"user_setAddLedger",
      user_setStoresQuery:"user_setStoresQuery",
      user_setStoreFindQuery:"user_setStoreFindQuery",
      user_setShopIntoQuery:"user_setShopIntoQuery",
      user_setThirdsQuery:"user_setThirdsQuery",
      user_setAppletQuery:"user_setAppletQuery",
      user_setIpSettingQuery:"user_setIpSettingQuery",
      system_setStaffQuery:"system_setStaffQuery",
      user_setTerminalConfig:"user_setTerminalConfig",
      electron_setInfolist:"electron_setInfolist",
      electron_setDeposit:"electron_setDeposit",
      electron_setGild:"electron_setGild",
      user_setIntelligenceList:"user_setIntelligenceList",
    }),
    // 重置验证码
    reImageCode() {
      this.imageCode = URL_API.codeUrl + "?num=" + Math.random();
    },
    // 退出登录后，显示当天时间
    newDate(){
          this.setDeal(null);
          this.setTest(null);
          this.setTradingRefund(null);
          this.setTradingRefundSh(null);
          this.trading_setPaidquery(null);
          this.trading_setAccount(null);
          this.trading_setAccount_tk(null);
          this.trading_setAccount_df(null);
          this.trading_setRefund_sg(null);
          this.trading_setGuarantee(null);
          this.trading_setTransfer_zz(null);
          this.trading_setCustoms(null);
          this.data_setSdfx(null);
          this.operation_setJydd(null);
          this.operation_setTkdd(null);
          this.operation_setDfdd(null);
          this.operation_setFzjy(null);
          this.operation_setFzdf(null);
          this.operation_setFzjs(null);
          this.operation_setFzzz(null);
          this.operation_setDeal(null);
          this.operation_setRefund(null);
          this.operation_setApplyfor(null);
          this.operation_setAccount(null);
          this.operation_setAccountpay(null);
          this.operation_setRefundJS(null);
          this.operation_setGuarantee(null);
          this.operation_setTransferZZ(null);
          this.system_setLogsQuery(null);
          this.trading_setRefund_sgjssh(null);
          this.operation_setFundUnfreeze(null);
          //显示前一天时间
          this.trading_setAutomatic(null);
          this.data_setShtj(null);
          this.data_setDptj(null);
          this.data_setJghz(null);
          this.data_setQdhz(null);
          this.data_setShhz(null);
          this.data_setDlshz(null);
          this.bill_setSearchQuery(null);
          this.bill_setCollecthQuery(null);
          this.platform_setoverview_jygk(null);
          this.platform_setoverview_sygk(null);
          this.data_setnalysis_ipfx(null);

          //
          this.trading_setManage(null);
          this.trading_setAccount_list(null);
          this.user_setOrganQuery(null);
          this.user_setBusinessQuery(null);
          this.user_setAgentsQuery(null);
          this.user_setSubaccountQuery(null);
          this.user_setLedgerQuery(null);
          this.user_setAddLedger(null);
          this.user_setStoresQuery(null);
          this.user_setStoreFindQuery(null);
          this.user_setShopIntoQuery(null);
          this.user_setThirdsQuery(null);
          this.user_setAppletQuery(null);
          this.user_setIpSettingQuery(null);
          this.system_setStaffQuery(null);
          this.user_setTerminalConfig(null);
          this.electron_setInfolist(null);
          this.electron_setDeposit(null);
          this.electron_setGild(null);
          this.user_setIntelligenceList(null);
    },
    // 登录
    login() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.username) {
        this.$message.error("管理后台账号不能为空！");
        return;
      }
      if (!data.password) {
        this.$message.error("密码不能为空！");
        return;
      }
      // if (!data.randCode) {
      // 	this.$message.error('验证码不能为空！')
      // 	return;
      // }
      if (!data.validateCode) {
        this.$message.error("验证码不能为空！");
        return;
      }
      if (this.checkUser) {
        localStorage.setItem("username", data.username);
      } else {
        localStorage.removeItem("username");
      }
      data.password = md5(data.password);
      data.type = "login";
      isLogin(data).then((res) => {
        if (res) {
          sessionStorage.setItem("userInfo", JSON.stringify(res.resultData));
          this.$store.state.isActiveHead = true;
          sessionStorage.setItem("tabs", JSON.stringify([]));
          sessionStorage.setItem("active_tab", "");

          console.log(res.resultData)

          this.$store.commit("setToken", res.resultData.token);

          this.newDate();

          this.$router.replace({
            path: "/",
          });
        } else {
          this.reImageCode();
        }
      });
    },
    handlerIptClick(){
      this.readonly = false;
    },
    setPass() {
      this.phone = "";
      this.setPsssform = {
        username: "",
        validateCode: "",
        newPassword: "",
        rePassword: "",
      };
      this.timeOut = 0;
      this.passShow = true;
    },
    getCode(active) {
      // 点击主页获取验证码时
      if (active) {
        if (this.timeOut1 != 0) {
          return;
        }
        if (!this.formData.username) {
          this.$message.error("请输入管理后台账号");
          return;
        }
        let data = {
          username: this.formData.username,
          type:"login",
        };
        this.timeOut1 = 60;
        validatecode(data).then((res) => {
          if (res.resultStatus) {
            this.codeShow1 = setInterval(() => {
              if (this.timeOut1 == 0) {
                clearInterval(this.codeShow1);
              } else {
                this.timeOut1--;
              }
            }, 1000);
          } else {
            clearInterval(this.codeShow1);
            this.timeOut1 = 0;
          }
        });
      } else {
        //点忘记密码的获取验证码时
        if (this.timeOut != 0) {
          return;
        }
        if (!this.setPsssform.username) {
          this.$message.error("请填写账户名");
          return;
        }
        let data = {
          username: this.setPsssform.username,
          type:"resetpwd",
        };
        this.timeOut = 60;
        validatecode(data).then((res) => {
          if (res.resultStatus) {
            this.codeShow = setInterval(() => {
              if (this.timeOut == 0) {
                clearInterval(this.codeShow);
              } else {
                this.timeOut--;
              }
            }, 1000);
          } else {
            clearInterval(this.codeShow);
            this.timeOut = 0;
          }
        });
      }

      // let data = {
      //   username: active ? this.formData.username : this.setPsssform.username,
      // };
      // this.timeOut = 60;
      // validatecode(data).then((res) => {
      //   if (res) {
      //     this.codeShow = setInterval(() => {
      //       if (this.timeOut == 0) {
      //         clearInterval(this.codeShow);
      //       } else {
      //         this.timeOut--;
      //       }
      //     }, 1000);
      //   } else {
      //     clearInterval(this.codeShow);
      //     this.timeOut = 0;
      //   }
      // });
    },
    editPass() {
      let data = JSON.parse(JSON.stringify(this.setPsssform));
      let passwordTest = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
      if (!data.username) {
        this.$message.error("账户名必填");
        return;
      }
      if (!data.validateCode) {
        this.$message.error("验证码必填");
        return;
      }
      if (!data.newPassword && !data.rePassword) {
        this.$message.error("密码必填");
        return;
      }
      // 正则验证密码复杂度 
      if (
        !passwordTest.test(
          data.newPassword
        )
      ) {
        return this.$message.error(
          "密码必须包含：大小写字母, 数字, 特殊符号, 长度至少8位以上"
        );
      }
      if (data.newPassword != data.rePassword) {
        this.$message.error("重复密码不相同");
        return;
      }
      data.type="resetpwd";
      data.newPassword = md5(data.newPassword);
      delete data.rePassword;
      resetpwd(data).then((res) => {
        if (res.resultStatus) {
          this.passShow = false;
          this.$message.success("密码修改成功");
        }
      });
    },
  }
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../assets/login_bc1.png) no-repeat center 50%/cover;
}

.loginBox {
  width: 6.6458rem;
  height: 3.9499rem;
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
}

.loginBox .leftBox,
.loginBox .rightBox {
  width: 50%;
  height: 100%;
}

.loginBox .leftBox .logo {
  width: 100%;
  height: 100%;
  background: url(../../assets/login_icon.png) no-repeat center 50%/cover;
}

.loginBox .leftBox .logo .logoImg {
  width: 1.5674rem;
  height: 0.2517rem;
  margin-bottom: 0.6270rem;
  background-image: url(../../assets/logoL.png);
  background-size: 100% 100%;
  margin-top: 30px;
  margin-left: 30px;
}

.loginBox .rightBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 65.834%;
}

.form h1 {
  color: #000000;
  font-size: 0.1505rem;
  font-weight: 400;
  font-family: "MicrosoftYaHei";
  margin-bottom: 0.1881rem;
}

.form h2 {
  margin-top: 0.1567rem;
  color: var(--title);
  font-size: 0.1003rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
}

.form h2 i {
  color: var(--themeColor);
  padding-right: 3px;
}

.form .inputs {
  margin-top: 0.0878rem;
  width: 100%;
}

.form .inputs.min {
  width: calc(100% - 37.718% - 20px);
}

/* .form .img_code {
  float: right;
  margin-top: 0.0878rem;
  display: inline-block;
  width: 132px;
  height: 0.2884rem;
  cursor: pointer;
}

.form .img_code > img {
  vertical-align: middle;
  width: 100%;
  height: 100%;
} */

.form .img_code {
  float: right;
  margin-top: 0.0878rem;
  display: inline-block;
  width: 37.718%;
  height: 0.2884rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.1003rem;
  line-height: 0.2884rem;
  color: #ffffff;
  background-color: var(--themeColor);
  text-align: center;
}

.form .button {
  margin-top: 0.2508rem;
  height: 0.3009rem;
  background-color: var(--themeColor);
  border-radius: 4px;
  line-height: 0.3009rem;
  text-align: center;
  font-size: 0.1129rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.form .setUser {
  margin-top: 0.1003rem;
  height: 0.2508rem;
  line-height: 0.2508rem;
  font-size: 0.0878rem;
}

.form .setUser div:first-child {
  float: left;
  cursor: pointer;
}

.form .setUser .el-checkbox {
  color: #696969;
}

.form .setUser div:last-child {
  float: right;
  color: var(--themeColor);
  cursor: pointer;
}

/deep/ .loginBoxDoalog {
  width: 3.07213rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.el-dialog {
  margin: auto;
}

.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBoxDoalog .form {
  width: 81.4%;
}

.loginBoxDoalog .loginBox {
  padding: 0.08rem 0.1881rem 0.1881rem;
  width: 3.07213rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBoxDoalog .loginBox .getCode {
  float: right;
  margin-top: 0.0rem;
  display: inline-block;
  width: 24%;
  height: 0.2884rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.0878rem;
  line-height: 0.2884rem;
  text-align: right;
  color: var(--themeColor);
}

.loginBoxDoalog .loginBox h2 {
  height: 0.2257rem;
  font-size: 0.0878rem;
  line-height: 0.2257rem;
  color: #999;
  text-align: left;
  margin-top: 0;
  font-weight: normal;
}

.loginBoxDoalog .loginBox .form .inputs {
  margin-top: 0px;
  margin-bottom: 0.0878rem;
}

.loginBoxDoalog .loginBox .form .inputs.min {
  margin-top: 0px;
  width: calc(100% - 24% - 10px);
}

.addBt {
  width: 0.3761rem;
  height: 0.1881rem;
  background-color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
  color: #ffffff !important;
  font-size: 0.0752rem;
  padding: 0;
}

.exitBt {
  width: 0.3761rem;
  height: 0.1881rem;
  background-color: #ffffff !important;
  color: var(--title) !important;
  border-color: #d7dae2 !important;
  font-size: 0.0752rem;
  padding: 0;
}

.login .form .inputs input {
  height: 0.2884rem;
  border: 0;
  padding: 0;
  border-bottom: 1px solid #e2e2e2;
  border-radius: 0;
}

.rightBox /deep/ .el-input .el-input__inner {
  height: 0.2884rem;
  border: 0 !important;
  padding: 0;
  border-bottom: 1px solid #e2e2e2 !important;
  border-radius: 0;
}

.loginModel /deep/ .el-input__inner {
  height: 0.2884rem;
}

.login .form .inputs input::-webkit-input-placeholder {
  font-size: 0.0878rem;
}

/deep/ .el-checkbox__label,
/deep/ .el-checkbox {
  font-size: 0.0878rem !important;
}

.login .form .setUser .el-checkbox__input:not(.is-checked) .el-checkbox__inner {
  width: 0.0878rem;
  height: 0.0878rem;
  border-radius: 0;
  border-color: #696969 !important;
  background-color: #fff;
}

@media screen and (max-width: 784px) {
  .loginBox .leftBox .left_img {
    display: none;
  }

  .loginBox {
    width: 80%;
    flex-flow: wrap;
    height: auto;
  }

  .loginBox .leftBox,
  .loginBox .rightBox {
    width: 100%;
  }

  .loginBox .rightBox .form {
    width: 80%;
    margin: 0.1881rem 0px;
  }

  /deep/ .loginBoxDoalog {
    width: 80%;
  }

  .loginBox .leftBox .logo {
    background: url(/assets/img/login_icon.5be5d6e1.png) no-repeat left top;
    background-color: var(--themeColor);
  }

  .loginBoxDoalog .loginBox {
    width: 100%;
  }

  .loginBoxDoalog .form {
    width: 100%;
  }

  .loginBox .leftBox .logo .logoImg {
    margin-bottom: 0.1881rem;
  }
}
</style>
