import Vue from "vue";
import Vuex from "vuex";
import createPersistedstate from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "", //存储token

    // isCollapse: false,//echact监听重绘变量

    user_organDetail: null, //机构新增
    user_businessDetail: null, //商户新增
    user_agentsDetail: null, //代理商新增
    user_subaccountDetail: null, //分账商户新增
    user_storesManualDetail: null, //店铺新增（手工）
    user_storesApiDetail: null, //店铺新增（API）
    user_thirdsDetail: null, //小程序新增（三方）
    user_terminalConfig: null, //终端小程序配置
    user_storesConfigDetail: null, //店铺配置
    operation_test: null, //收款测试
    trading_deal: null, //交易查询
    trading_refund: null, //退款查询
    trading_refund_sh: null, //退款审核查询
    trading_account_list: null, //账户列表
    trading_paidquery: null, //代付查询
    trading_manage: null, //分账管理
    trading_account: null, //分账交易查询
    trading_account_tk: null, //分账退款查询
    trading_refund_sg: null, //手工结算查询
    trading_refund_sgjssh: null, //手工结算审核查询
    trading_automatic: null, //自动结算查询
    trading_guarantee: null, //担保查询
    trading_refund_zz: null, //账户转账
    trading_customs: null, //海关推单查询
    operation_refund: null, //退款通知查询
    operation_deal: null, //交易通知查询
    operation_applyfor: null, //待付通知查询
    operation_account: null, //分账通知查询
    operation_guarantee: null, //担保通知查询
    operation_refundJS: null, //结算通知查询
    operation_transferZZ: null, //转账通知查询
    operation_fund_unfreeze: null, //资金解冻通知查询

    operation_jydd: null, //交易订单管理
    operation_tkdd: null, //退款订单管理
    operation_dfdd: null, //代付订单管理
    operation_fzjy: null, //分账交易订单管理
    operation_fzdf: null, //分账代付订单管理
    operation_fzjs: null, //分账结算订单管理
    operation_fzzz: null, //分账转账订单管理

    user_organQuery: null, //机构列表查询
    user_businessQuery: null, //商户管理查询
    user_agentsQuery: null, //机构管理查询
    user_subaccountQuery: null, //分账商户列表查询
    user_ledgerQuery: null, //电子账簿商户列表
    user_addLedger: null, //电子账簿商户列表-新增
    user_storesQuery: null, //店铺列表查询
    user_shopIntoQuery: null, //进件队列列表查询
    user_thirdsQuery: null, //第三方列表查询
    user_appletQuery: null, //小程序列表查询
    user_ipSettingQuery: null, // IP制导列表查询
    user_intelligenceList: null, //智能路由配置

    trading_account_df: null, // 分账代付查询列表
    operation_accountpay: null, // 分账代付通知列表
    system_logsQuery: null, //登录日志查询列表
    system_staffQuery: null, //员工管理查询列表
    bill_searchQuery: null, //账单管理账单查询列表
    bill_collectQuery: null, //账单管理资金汇总-交易账单查询列表
    bill_collectSubQuery: null, //账单管理资金汇总-分账账单查询列表

    user_storeFindQuery: null, //店铺查询页面

    data_shtjQuery: null, //商户统计页面
    data_dptjQuery: null, //店铺统计页面
    data_sdfxQuery: null, //时段分析页面

    data_jghzQuery: null, //机构汇总页面
    data_qdhzQuery: null, //渠道汇总页面
    data_shhzQuery: null, //商户汇总页面
    data_dlshzQuery: null, //代理商汇总页面

    platform_overview_jygk: null, //平台概况交易概况
    platform_overview_sygk: null, //平台概况收益概况

    data_nalysis_ipfx: null, //数据分析终端IP分析

    electron_infolist: null, //电子账簿-电子账簿列表
    electron_deposit: null, //电子账簿-入金查询
    electron_gild: null, //电子账簿-出金查询
  },
  getters: {
    getToken(state) {
      return state.token;
    },
  },
  mutations: {
    // 修改token，并将token存入localStorage
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
      // console.log('localstorage保存token成功！');
    },

    //echact监听重绘变量
    // commitCollapse(state, bool) {
    //   state.isCollapse = bool;
    // },

    // delToken(state) {
    //   state.token = "";
    //   storage.remove("token");
    // },
    // 可选
    // setTokenInfo(state, token) {
    //   state.token = token;
    //   console.log("setTokenInfo111",token)
    // },

    // 机构新增数据存储
    user_setOrgan(state, value) {
      state.user_organDetail = value;
    },
    //商户新增数据存储
    user_setBusiness(state, value) {
      state.user_businessDetail = value;
    },
    //代理商新增数据存储
    user_setAgents(state, value) {
      state.user_agentsDetail = value;
    },
    //分账商户新增数据存储
    user_setSubaccount(state, value) {
      state.user_subaccountDetail = value;
    },
    //店铺新增（手工）数据存储
    user_setStoresManual(state, value) {
      state.user_storesManualDetail = value;
    },
    //店铺新增（API）数据存储
    user_setStoresApi(state, value) {
      state.user_storesApiDetail = value;
    },
    // 小程序新增（三方）数据存储
    user_setThirds(state, value) {
      state.user_thirdsDetail = value;
    },
    // 小程序新增（三方）数据存储
    user_setStoresConfig(state, value) {
      state.user_storesConfigDetail = value;
    },
    // 收款测试数据存储
    operation_setTest(state, value) {
      state.operation_test = value;
    },
    // 交易查询数据存储
    trading_setDeal(state, value) {
      state.trading_deal = value;
    },
    // 退款查询数据存储
    trading_setRefund(state, value) {
      state.trading_refund = value;
    },
    // 退款审核查询数据存储
    trading_setRefund_sh(state, value) {
      state.trading_refund_sh = value;
    },
    //账户列表
    trading_setAccount_list(state, value) {
      state.trading_account_list = value;
    },
    // 代付查询数据存储
    trading_setPaidquery(state, value) {
      state.trading_paidquery = value;
    },
    //分账管理数据存储
    trading_setManage(state, value) {
      state.trading_manage = value;
    },
    // 分账交易查询数据存储
    trading_setAccount(state, value) {
      state.trading_account = value;
    },
    // 分账退款查询数据存储
    trading_setAccount_tk(state, value) {
      state.trading_account_tk = value;
    },
    // 手工结算查询数据存储
    trading_setRefund_sg(state, value) {
      state.trading_refund_sg = value;
    },
    // 手工结算审核查询数据存储
    trading_setRefund_sgjssh(state, value) {
      state.trading_refund_sgjssh = value;
    },
    // 自动结算查询数据存储
    trading_setAutomatic(state, value) {
      state.trading_automatic = value;
    },
    // 担保查询数据存储
    trading_setGuarantee(state, value) {
      state.trading_guarantee = value;
    },
    // 账户转账查询数据存储
    trading_setTransfer_zz(state, value) {
      state.trading_refund_zz = value;
    },
    // 海关退单查询数据存储
    trading_setCustoms(state, value) {
      state.trading_customs = value;
    },

    // 交易订单管理数据存储
    operation_setJydd(state, value) {
      state.operation_jydd = value;
    },
    // 退款订单管理数据存储
    operation_setTkdd(state, value) {
      state.operation_tkdd = value;
    },
    // 代付订单管理数据存储
    operation_setDfdd(state, value) {
      state.operation_dfdd = value;
    },
    // 分账交易订单管理数据存储
    operation_setFzjy(state, value) {
      state.operation_fzjy = value;
    },
    // 分账代付订单管理数据存储
    operation_setFzdf(state, value) {
      state.operation_fzdf = value;
    },
    // 分账结算订单管理数据存储
    operation_setFzjs(state, value) {
      state.operation_fzjs = value;
    },
    // 分账转账订单管理数据存储
    operation_setFzzz(state, value) {
      state.operation_fzzz = value;
    },

    // 退款通知查询数据存储
    operation_setRefund(state, value) {
      state.operation_refund = value;
    },
    // 交易通知查询数据存储
    operation_setDeal(state, value) {
      state.operation_deal = value;
    },
    // 资金解冻通知查询数据存储
    operation_setFundUnfreeze(state, value) {
      state.operation_fund_unfreeze = value;
    },
    // 代付通知查询数据存储
    operation_setApplyfor(state, value) {
      state.operation_applyfor = value;
    },
    // 分账通知查询数据存储
    operation_setAccount(state, value) {
      state.operation_account = value;
    },
    // 担保通知查询数据存储
    operation_setGuarantee(state, value) {
      state.operation_guarantee = value;
    },
    // 结算通知查询数据存储
    operation_setRefundJS(state, value) {
      state.operation_refundJS = value;
    },
    // 转账通知查询数据存储
    operation_setTransferZZ(state, value) {
      state.operation_transferZZ = value;
    },
    // 机构列表查询数据存储
    user_setOrganQuery(state, value) {
      state.user_organQuery = value;
    },
    // 商户管理查询数据存储
    user_setBusinessQuery(state, value) {
      state.user_businessQuery = value;
    },
    // 机构管理查询数据存储
    user_setAgentsQuery(state, value) {
      state.user_agentsQuery = value;
    },
    // 分账商户列表查询数据存储
    user_setSubaccountQuery(state, value) {
      state.user_subaccountQuery = value;
    },
    //电子账簿商户列表
    user_setLedgerQuery(state, value) {
      state.user_ledgerQuery = value;
    },
    //电子账簿商户列表-新增
    user_setAddLedger(state, value) {
      state.user_addLedger = value;
    },
    // 店铺列表列表查询数据存储
    user_setStoresQuery(state, value) {
      state.user_storesQuery = value;
    },
    // 店铺查询页面数据存储
    user_setStoreFindQuery(state, value) {
      state.user_storeFindQuery = value;
    },
    // 进件队列查询数据存储
    user_setShopIntoQuery(state, value) {
      state.user_shopIntoQuery = value;
    },
    // 第三方列表查询数据存储
    user_setThirdsQuery(state, value) {
      state.user_thirdsQuery = value;
    },
    // 小程序列表查询数据存储
    user_setAppletQuery(state, value) {
      state.user_appletQuery = value;
    },
    // 终端小程序配置数据存储
    user_setTerminalConfig(state, value) {
      state.user_terminalConfig = value;
    },
    // IP制导列表存储
    user_setIpSettingQuery(state, value) {
      state.user_ipSettingQuery = value;
    },
    //智能路由配置
    user_setIntelligenceList(state, value) {
      state.user_intelligenceList = value;
    },

    // 分账代付查询存储
    trading_setAccount_df(state, value) {
      state.trading_account_df = value;
    },
    // 分账代付通知存储
    operation_setAccountpay(state, value) {
      state.operation_accountpay = value;
    },
    //登录日志查询数据存储
    system_setLogsQuery(state, value) {
      state.system_logsQuery = value;
    },
    // 员工列表查询存储
    system_setStaffQuery(state, value) {
      state.system_staffQuery = value;
    },
    //账单查询列表存储
    bill_setSearchQuery(state, value) {
      state.bill_searchQuery = value;
    },

    //资金汇总-交易账单查询列表存储
    bill_setCollecthQuery(state, value) {
      state.bill_collectQuery = value;
    },
    //资金汇总-分账账单查询列表存储
    bill_setCollectSubhQuery(state, value) {
      state.bill_collectSubQuery = value;
    },

    // 商户统计页面数据存储
    data_setShtj(state, value) {
      state.data_shtjQuery = value;
    },
    // 店铺统计页面数据存储
    data_setDptj(state, value) {
      state.data_dptjQuery = value;
    },
    // 时段分析页面数据存储
    data_setSdfx(state, value) {
      state.data_sdfxQuery = value;
    },

    // 机构汇总页面数据存储
    data_setJghz(state, value) {
      state.data_jghzQuery = value;
    },
    // 渠道汇总页面数据存储
    data_setQdhz(state, value) {
      state.data_qdhzQuery = value;
    },
    // 商户汇总页面数据存储
    data_setShhz(state, value) {
      state.data_shhzQuery = value;
    },
    // 代理商汇总页面数据存储
    data_setDlshz(state, value) {
      state.data_dlshzQuery = value;
    },

    //平台概况交易概况
    platform_setoverview_jygk(state, value) {
      state.platform_overview_jygk = value;
    },
    //平台概况收益概况
    platform_setoverview_sygk(state, value) {
      state.platform_overview_sygk = value;
    },
    //数据分析终端IP分析 data_nalysis_ipfx
    data_setnalysis_ipfx(state, value) {
      state.data_nalysis_ipfx = value;
    },

    //电子账簿-电子账簿列表
    electron_setInfolist(state, value) {
      state.electron_infolist = value;
    },
    //电子账簿-入金查询
    electron_setDeposit(state, value) {
      state.electron_deposit = value;
    },
    //电子账簿-出金查询
    electron_setGild(state, value) {
      state.electron_gild = value;
    },
  },
  actions: {},
  modules: {},

  plugins: [
    new createPersistedstate({
      token: localStorage.getItem("token"),
    }),
  ],
});
